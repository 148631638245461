import React, { useEffect } from "react";
import Image from "gatsby-image";
import { StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import GifSummer from '../../assets/images/problems/homeSummer.gif';
import GifWinter from '../../assets/images/problems/homeVentWinter.gif';
import GifGood from '../../assets/images/problems/homeVentGood.gif';

const QueryComp = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          companyInfo: allDataJson(filter: { id: { eq: "problems" } }) {
            edges {
              node {
                sections {
                  id
                  title
                  description
                  items{
                    title
                    text
                    image
                    alt
                  }
                }
              }
            }
          }
          images: allFile(filter: { relativePath: { glob: "problems/*.*" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 75) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <Problems data={data} />}
    />
  );
};

const Problems = ({ data }) => {
    let mainProbs = data.companyInfo.edges[0].node.sections.find(
        section => section.id === "problems"
    );
    let mainService = data.companyInfo.edges[0].node.sections.find(
      section => section.id === "probModels"
    );
    let images = data.images.edges;

    const { t } = useTranslation();

    function getProblemsComponents(){
        let comps = mainProbs.items.map(item => (
            <div key={item.title} className="col-md-4 post-item">
              <article className="post">
                  <div className="post-preview"><Image fluid={images.find(img=>img.node.relativePath.search(item.image) > -1 ).node.childImageSharp.fluid} alt={item.alt}/></div>
                  <div className="post-wrapper">
                      <div className="post-header">
                          <h2 className="post-title">{t(item.title)}</h2>
                      </div>
                      <div className="post-content">
                          <p>{t(item.text)}</p>
                      </div>
                  </div>
              </article>
          </div>
        ))
        return comps;
    }
    
    return (
      <>
        <section className="module">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                        <h1><strong>{t(mainService.title)}</strong></h1>
                        <p className="lead">{t(mainService.description)}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="space" style={{marginTop:`60px`}}></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="feature">
                            <div className="feature-thumb"><img src={GifSummer}/></div>
                            <div className="feature-content">
                                <h6>{t(mainService.items[0].title)}</h6>
                                <p>{t(mainService.items[0].text)}</p><a className="feature-link" href={t(mainService.items[0].link)}>{t(mainService.items[0].linkText)}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="feature">
                            <div className="feature-thumb"><img src={GifWinter}/></div>
                            <div className="feature-content">
                                <h6>{t(mainService.items[1].title)}</h6>
                                <p>{t(mainService.items[1].text)}</p><a className="feature-link" href={t(mainService.items[1].link)}>{t(mainService.items[1].linkText)}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="feature">
                            <div className="feature-thumb"><img src={GifGood}/></div>
                            <div className="feature-content">
                                <h6>{t(mainService.items[2].title)}</h6>
                                <p>{t(mainService.items[2].text)}</p><a className="feature-link" href={t(mainService.items[2].link)}>{t(mainService.items[2].linkText)}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                  <h1><strong>{t(mainProbs.title)}</strong></h1>
                  <p className="lead">{t(mainProbs.description)}</p>
              </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="space" style={{marginTop:`60px`}}></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row row-post-masonry">
                        {getProblemsComponents()}
                    </div>
                </div>
            </div>
          </div>
      </>
    );
}
export default QueryComp;