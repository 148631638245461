import React from 'react'
import Layout from '../components/layout/layout'
import withI18next from "../components/utilities/withI18next"
import Problem from "../components/problems/problems"
import Hero from "../components/hero/hero"
import SEO from "../components/seo/seo"

const Page = props => {
  return (
    <>
      <SEO title={props.t("seoProblemsTitle")} desc={props.t("seoProblemsDescription")} />
      <Layout>
          <Hero image="problems" />
          <Problem/>
      </Layout>
    </>
  )
}
export default withI18next({ ns: "common" })(Page);